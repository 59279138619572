import React, { useEffect } from 'react'
import './Waiver.scss'
import HeroBanner from '../../components/HeroBanner'
import Layout from '../../components/Layout'
import { Helmet } from 'react-helmet'
import useGlobal from '../../util/GlobalHook'
import { graphql } from 'gatsby'

const Waiver = ({ data }) => {
  const [globalState, globalActions] = useGlobal()

  useEffect(() => {
    if (globalState.loadedHealCode === true) {
      window.location.reload()
    } else {
      globalActions.updateLoadedHealCode(true)
    }
  }, [])

  return (
    <Layout>
      <Helmet>
        <script
          src="https://widgets.healcode.com/javascripts/healcode.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <div className="Waiver">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="Sign the Waiver"
          caption="Once you fill out the waiver below, download our app to schedule your next visit"
        />
        <div className="container content">
          <h2>
            Everyone who attends OCR Academy must sign the waiver before joining
            any class or open gym time.
          </h2>
          <p>
            <strong>Already a member?</strong> Then just head on over to our
            schedule - you're good to go!
          </p>

          <p>
            <strong>ATTN Parents:</strong> We cannot ask a minor to fill out our
            waiver, so please fill the waiver in your child's name below. Once
            you click agree to our awesome waiver, that is it, you are done.
            See, that was easy! Unfortunately, our system will not recognize
            multiple accounts using the same email and password. So if you are a
            parent and you are looking to register yourself and your children,
            please do not use the same password, the password is to protect that
            person's identity, and the system will think its the same person
            registering, so please if you are using same email, use a different
            password. . For security reasons your password must contain 1
            capital letter, 1 number and be at least 8 characters long.{' '}
          </p>

          <p>
            <strong>Note:</strong> If you are planning on coming in with
            multiple people, all participants must be signed into the same time
            slot. If you are having trouble with our waiver or signing
            participants into a time slot- give us a call and we can help you
            out!{' '}
          </p>

          <p>
            We encourage you to bring your own chalk as you will most likely
            need it. But please respect the cleanliness of our facility.{' '}
          </p>

          <p>
            In filling out this waiver, your personal information is collected
            by MindBody who does not solicit the information to any third
            parties.
          </p>

          <healcode-widget
            data-type="registrations"
            data-widget-partner="object"
            data-widget-id="84174298e4e"
            data-widget-version="0.1"
          ></healcode-widget>
        </div>
      </div>
    </Layout>
  )
}

export default Waiver

export const query = graphql`
  {
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/09/children.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
